







export default {
  name: 'PolygonSelectPanel',
  components: {
    
  },
  data () {
    return {

    }
  }
}

